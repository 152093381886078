import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL } from "@/const";
export default {
  data() {
    return {
      validate: {
        // 企業情報
        companyInfo: {
          contractName: { isValid: true, errMsg: "" },
          zipcode: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          selectGroup: { isValid: true, errMsg: "" },
          selectGroupOptVal: { isValid: true, errMsg: "" },
          legalPersonalityTypeId: { isValid: true, errMsg: "" },
          contractGroupName: { isValid: true, errMsg: "" },
          jwnetLineTypeId: {isValid: true, errMsg: ""},
        },
        contractInfo: {
          contractType: { isValid: true, errMsg: "" },
          startDate: { isValid: true, errMsg: "" },
          endDate: { isValid: true, errMsg: "" },
          invoiceMethod: { isValid: true, errMsg: "" },
          invoiceTiming: { isValid: true, errMsg: "" },
          zipcodeFirst: { isValid: true, errMsg: "" },
          zipcodeSecond: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          staffDepartment: { isValid: true, errMsg: "" },
          staffMail: { isValid: true, errMsg: "" },
          staffName: { isValid: true, errMsg: "" },
        },
        accountInfo: {
          department: { isValid: true, errMsg: "" },
          name: { isValid: true, errMsg: "" },
          mail: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          mobile: { isValid: true, errMsg: "" },
        },
      },

      isValidForm: {
        companyInfo: true,
        contractInfo: true,
        accountInfo: true,
      },
    };
  },
  methods: {
    // 会社名/営業所名 バリデートチェック
    async companyInfoValidateCheck() {
      const v = this.validate.companyInfo;
      const form = this.form;
      let isValidForm = true;

      if (!v.contractName.isValid) {
        v.contractName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.zipcode.isValid) {
        v.zipcode.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      } else {
        const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${form.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (this.selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    } else {
                      if (this.selectedInvoicePrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (form.addressInfo.address === address.address2) {
                        addressFlg = true;
                      }
                    } else {
                      if (form.invoiceAddressInfo.address === address.address2) {
                        addressFlg = true;
                      }
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
      if (!v.prefecture.isValid) {
        v.prefecture.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.address.isValid) {
        v.address.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.addressInfo.buildingName && !v.building.isValid) {
        v.building.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.tel.isValid) {
        v.tel.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.fax && !v.fax.isValid) {
        v.fax.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      if (!this.selectedGroupOptVal) {
        v.selectGroupOptVal.errMsg = "必須項目です、ご選択ください。";
        isValidForm = false;
      }

      // 新規グループ作成時のみ
      if (this.selectedGroupOptVal === "create-new") {
        if (!v.legalPersonalityTypeId.isValid) {
          v.legalPersonalityTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.contractGroupName.isValid) {
          v.contractGroupName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }
      // 既存グループから選択時のみ
      if (this.selectedGroupOptVal === "select-group") {
        if (!v.selectGroup.isValid) {
          v.selectGroup.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }
      if (!v.jwnetLineTypeId.isValid) {
        v.jwnetLineTypeId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    contractInfoValidateCheck() {
      const v = this.validate.contractInfo;
      let isValidForm = true;

      if (!v.contractType.isValid) {
        v.contractType.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.startDate.isValid) {
        v.startDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.endDate.isValid) {
        v.endDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.invoiceMethod.isValid) {
        v.invoiceMethod.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.invoiceTiming.isValid) {
        v.invoiceTiming.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffDepartment.isValid) {
        v.staffDepartment.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffMail.isValid) {
        v.staffMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffName.isValid) {
        v.staffName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      // 他の住所選択時のみ
      if (!this.form.isSameAddress) {
        if (!v.zipcodeFirst.isValid) {
          v.zipcodeFirst.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.zipcodeSecond.isValid) {
          v.zipcodeSecond.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.prefecture.isValid) {
          v.prefecture.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.address.isValid) {
          v.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.form.invoiceAddressInfo.buildingName && !v.building.isValid) {
          v.building.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    accountInfoValidateCheck() {
      const v = this.validate.accountInfo;
      let isValidForm = true;

      if (!v.department.isValid) {
        v.department.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.name.isValid) {
        v.name.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      // 氏名の文字数の制限: 全角12文字以内のチェック
      if (this.form.accountName) {
        if (this.form.accountName.length > 12) {
          v.name.errMsg = "全角12文字以内で入力してください。";
          isValidForm = false;
        }
      }
      if (!v.mail.isValid) {
        v.mail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.accountTel.length > 0 && !v.tel.isValid) {
        v.tel.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.accountMobilePhone.length > 0 && !v.mobile.isValid) {
        v.mobile.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    async runValidate() {
      let isValidForm = false;
      this.isValidForm.companyInfo = await this.companyInfoValidateCheck();
      this.isValidForm.contractInfo = this.contractInfoValidateCheck();
      this.isValidForm.accountInfo = this.accountInfoValidateCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
